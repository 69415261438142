import type { AccountConfig } from '#types/config/pages/account'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    heading: 'mt-10 mb-8 title-1',
    navigation: '<lg:b-t',
    navItem: '@hactive:underlined fw-normal',
  },
  address: {
    dialogOptions: { type: 'panel' }
  },
  creditCards: {
    dialogOptions: { type: 'panel' }
  },
  orderHistory: {
    brandClasses: {
      grid: 'gap-4 pb-4 md:(grid gap-y-0 pb-6) lg:pb-8',
      itemsDetail: {
        attributes: 'mt-2',
        container: 'mt-4 text-sm <md:hidden <lg:mt-2',
        grid: 'grid gap-2 md:gap-4 <md:(pr-2 pt-2)',
        hiddenItemsCount: 'bg-grey-80 fw-bold text-base md:text-md'
      },
      notification: 'col-span-2 mb-4 lg:mb-6',
      orderDate: 'fw-bold pt-4',
      viewOrderDetailsButton: 'mt-2'
    },
    brandStyles: {
      'grid-cols': {
        lg: '16.25rem 1fr'
      }
    },
    image: {
      alignFill: 'center',
      height: { lg: 488, md: 136 },
      width: { lg: 390, md: 109 },
      unSharpMask: 100
    },
    itemsCount: {
      md: 3
    },
    notificationVariant: 'left'
  },
} satisfies RecursivePartial<AccountConfig>
