import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    link: 'fw-medium px-3',
    popover: 'group relative',
    shopAll: 'subtitle-3 fw-bold gap-1 p-2 @hactive:underlined',
    visualSelectorsTitle: ''
  },
  underlineOffset: '-0.1rem'
} satisfies RecursivePartial<CMSConfig['megaMenuL1Desktop']>
