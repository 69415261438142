import type { RecursivePartial } from '#types/utils'
import type { FormConfig } from '#types/config/components/vf/form'

export default {

  iconSize: 'md',
  sort: {
    brandClasses: {
      label: 'b-b center h-6',
      icon: 'ml-1',
      text: 'text-sm'
    }
  }
} satisfies RecursivePartial<FormConfig['select']>
