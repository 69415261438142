import type { RecursivePartial } from '#types/utils'
import type { PagesConfig } from '#types/config/pages'

export default {
  newsletters: [{
    type: 'newsletter'
  }, {
    type: 'loyaltyNewsletter',
    onlyForLoyaltyMember: true,
    requiredLocales: [
      'de-de',
      'en-gb',
      'es-es',
      'fr-fr',
      'it-it',
      'nl-nl',
    ]
  }],
  interestOnlyForSubscription: {
    requiredLocales: [
      'de-at',
      'de-be',
      'de-ch',
      'de-de',
      'de-lu',
      'en-cz',
      'en-dk',
      'en-fi',
      'en-gb',
      'en-ie',
      'en-se',
      'es-es',
      'fr-be',
      'fr-ch',
      'fr-fr',
      'fr-lu',
      'it-ch',
      'it-it',
      'nl-be',
      'nl-nl',
      'pl-pl',
      'pt-pt'
    ]
  },
  showPostalCode: {
    requiredLocales: ['en-us', 'en-ca', 'fr-ca']
  },
} satisfies RecursivePartial<PagesConfig['profile']>
