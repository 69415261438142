import type { DialogConfig } from '#types/config/components/dialog'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    badge: 'm-1 bg-white/80',
    productNameInline: 'title-4',
    productNameModal: 'title-4'
  },
  dialogOptions: {
    type: 'panel'
  }
} satisfies RecursivePartial<DialogConfig['quickshop']>
