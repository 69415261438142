import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    tabs: 'pb-4 -mt-3 lg:pb-6'
  },
  sizeChart: {
    brandClasses: {
      footerContainer: 'mt-6',
      footerTitle: 'subtitle-4',
      footerDescription: 'body-3'
    }
  },
  measurements: {
    brandClasses: {
      type: 'subtitle-2 capitalize',
      title: 'subtitle-4',
      description: 'body-3',
      imgTitle: 'subtitle-4',
    },
    number: false,
    footer: true,
  }
} satisfies RecursivePartial<DialogConfig['sizeChart']>
