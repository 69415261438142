import type { RecursivePartial } from '#types/utils'
import type { ProductConfig } from '#types/config/components/product'

export default {
  bulletin: {
    brandClasses: {
      list: 'list-disc space-y-4'
    }
  },
  modalGrid: {
    dialogOptions: { type: 'panel' },
  }
} satisfies RecursivePartial<ProductConfig['accordions']>
