import type { StoreIdsConfig } from '#types/config/storeIdsConfig'

export default {
  storeId: {
    at: 10164,
    be: 10167,
    ca: 10154,
    ch: 10169,
    cz: 10173,
    de: 10160,
    dk: 10168,
    es: 10162,
    fi: 10172,
    fr: 10159,
    gb: 10158,
    ie: 10165,
    it: 10161,
    lu: 10170,
    nl: 10163,
    pl: 10174,
    pt: 10175,
    se: 10166,
    us: 10153
  }
} as StoreIdsConfig
