import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  hideHeader: false,
  brandClasses: {
    title: 'subtitle-2',
    wrapper: ''
  },
  dialogOptions: {
    type: 'panel',
    position: 'right'
  }
} satisfies RecursivePartial<DialogConfig['signInConfirmation']>
