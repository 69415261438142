import type { ProductListConfig } from '#types/config/components/product/list'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    filterAndSortCta: 'px-10 fw-normal',
    filtersToggle: 'px-6',
    gridSizeSelector: 'flex justify-end gap-2 py-4 <lg:hidden px-6',
    noResults: 'mt-6 px-10 text-center text-lg fw-bold lg:my-16',
    outerGrid: 'flex gap-4 lg:px-6',
    sideFilters: 'px-6'
  },
  dialogOptions: { type: 'panel' },
  filterAndSortCta: {
    iconSize: 'md',
    size: 'tiny',
    variant: 'secondary'
  },
  filtersToggle: true,
  grid: {
    gaps: {
      x: {
        sm: 'xs',
        md: 'xs',
        lg: 'sm'
      },
      y: {
        sm: 'sm',
        md: 'sm',
        lg: 'md'
      }
    }
  },
  hydrationMode: 'idle',
  pickupFilterAboveList: false,
  scrollToElementOptions: { offset: 0 }
} satisfies RecursivePartial<ProductListConfig>
