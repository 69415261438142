/*
- The value we send has to match the backend key. They are used for tagging content with email preferences and user segmenting so we cannot change them.
- In 1.5 this is fully configured in the CMS but we are decoupling this for Vans.
- There is also no endpoint to retrieve these options so we are configuring them here.
- Keeping this as a keys until we have confirmation this will be pulled from the CMS at some point
- These keys are mapped exactly to the translation files to interestsOptions object
*/

import type { InterestsConfig } from '#types/config/components/form/interests'

export default {
  brandClasses: {
    wrapper: 'mt-4 flex gap-2 wrap',
    wrapperDivided: 'mt-4 flex gap-2 wrap'
  },
  component: 'toggle',
  options: [
    'skateboarding',
    'surfing',
    'snowboarding',
    'bmx',
    'music',
    'drawing_and_painting',
    'design',
    'fashion',
    'photography',
    'videography'
  ],
  showOptionsLead: true,
  showShoeSizeSelector: true
} satisfies InterestsConfig
