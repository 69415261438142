export default `
# Vans ({domain}) robots.txt
User-agent: *

Disallow: */cart$
Disallow: */checkout*
Disallow: */order/success
Disallow: */search*
Disallow: */product/review
Disallow: /*/c/*?*
Allow: /*/c/*?cctld*
Disallow: /*/tag/*?*
Disallow: /*/explore/*?*
Disallow: /*/collections/*?*
Disallow: /*?CountryPref*
Disallow: /*/size-chart$
Disallow: /*/size-chart?

# AdsBot-Google
User-agent: AdsBot-Google
Disallow: */cart$
Disallow: */checkout/
Disallow: */order/success

# Amazon's user agent
User-agent: AmazonProductDiscoverybot
Disallow: /

#sitemap
Sitemap: {sitemapUrl}
`
