import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    panel: {
      header: 'subtitle-3 pr-3 lg:pr-4',
      close: 'duration rounded-sm p-1 @hactive:bg-brand-2',
      wrapper: 'p-6'
    },
    modal: {
      header: 'subtitle-3'
    }
  }
} satisfies RecursivePartial<VfConfig['dialog']>
