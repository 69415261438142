import type { RecursivePartial } from '#types/utils'
import type { RobisFormConfig } from '#types/config/components/form/robis'

export default {
  brandClasses: {
    form: 'f-col gap-6',
    ctaWrapper: '',
    fieldsWrapper: 'f-col gap-2',
  },
} satisfies RecursivePartial<RobisFormConfig>
