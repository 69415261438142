import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    title: {
      md: 'subtitle-4',
      sm: 'subtitle-4'
    },
    toggle: {
      md: 'py-4',
      sm: 'py-3'
    },
    wrapper: 'b-b b-brand-2'
  },
  iconSize: {
    md: 'md',
    sm: 'sm'
  }
} satisfies RecursivePartial<VfConfig['accordion']>
