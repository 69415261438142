import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    productName: 'subtitle-4',
    productPrice: 'body-3',
    productVariants: 'body-3',
    savings: '-mx-6 -mt-4',
    savingsText: 'body-3'
  }
} satisfies RecursivePartial<DialogConfig['miniCart']>
