import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    container: 'bg-grey-60',
    progress: 'bg-brand-1'
  },
  brandStyles: {
    container: {
      h: {
        sm: '0.125rem'
      }
    }
  }
} satisfies RecursivePartial<VfConfig['progressBar']>
