import type { CheckoutConfig } from '#types/config/checkout'

export default {
  NORA: {
    siteId: {
      DEV: 'e6462e28b955',
      QA: 'e6462e28b955',
      PREPROD: 'e6462e28b955',
      PROD: 'f943c7a9bc9a'
    }
  }
} satisfies CheckoutConfig['forter']
