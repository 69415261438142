import type { RecursivePartial } from '#types/utils'
import type { VfConfig } from '#types/config/components/vf'

export default {
  defaultSearchTerm: 'Shoes',
  iconSize: 'md',
  mobileNavIconSize: 'md',
  brandClasses: {
    button: 'rounded-r-sm'
  }
} satisfies RecursivePartial<VfConfig['search']>
