import type { CMSConfig } from '#types/config/components/cms'

export default {
  classControls: 'h-10 w-10 bg-brand-1/50 p-2 c-white shadow-sm @hactive:bg-brand-1/90',
  showArrowsOnHoverOnly: true,
  showArrowsOnHoverThreshold: 0.3,
  sizeControls: 'md',
  spacing: {
    none: 0,
    small: 0.125,
    medium: 0.25,
    large: 0.50
  },
} satisfies CMSConfig['collection']
