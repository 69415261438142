import type { SignUpConfig } from '#types/config/components/form/signUp'
import type { RecursivePartial } from '#types/utils'

export default {
  agreementCheckboxes: [
    {
      type: 'loyaltyTerms',
      locales: ['en-us'],
      required: true
    },
    {
      type: 'brandTerms',
      locales: ['en-ca', 'fr-ca'],
      required: true
    },
    {
      type: 'policy',
      required: true
    },
    {
      type: 'newsletter'
    }
  ],
  brandClasses: {
    checkboxesWrapper: 'space-y-4',
    heading: 'subtitle-4'
  },
  checkboxSize: 'sm',
  fieldsOrder: [
    'firstName',
    'lastName',
    'birthDate',
    'phone',
    'gender',
    'email',
    'password'
  ],
  minimalAge: 18,
  mobilePhone: {
    requiredLocales: ['en-ca', 'en-us', 'fr-ca']
  },
  onSuccessNavigateTo: '/account',
  showBirthDateField: true
} satisfies RecursivePartial<SignUpConfig>
