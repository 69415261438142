import type { CustomsApiConfig } from '#types/config/customsApi'

export default {
  caids: {
    'de-at': 'emea-de-at-sfcc-canvas',
    'de-be': 'emea-de-be-sfcc-canvas',
    'de-ch': 'emea-de-ch-sfcc-canvas',
    'de-de': 'emea-de-de-sfcc-canvas',
    'de-lu': 'emea-de-lu-sfcc-canvas',
    'en-ca': 'nora-en-ca-sfcc-canvas',
    'en-dk': 'emea-en-dk-sfcc-canvas',
    'en-gb': 'emea-en-gb-sfcc-canvas',
    'en-se': 'emea-en-se-sfcc-canvas',
    'en-ie': 'emea-en-se-sfcc-canvas',
    'en-us': 'nora-en-us-sfcc-canvas',
    'es-es': 'emea-es-es-sfcc-canvas',
    'fr-be': 'emea-fr-be-sfcc-canvas',
    'fr-ch': 'emea-fr-ch-sfcc-canvas',
    'fr-ca': 'nora-fr-ca-sfcc-canvas',
    'fr-fr': 'emea-fr-fr-sfcc-canvas',
    'fr-lu': 'emea-fr-lu-sfcc-canvas',
    'it-ch': 'emea-it-ch-sfcc-canvas',
    'it-it': 'emea-it-it-sfcc-canvas',
    'nl-be': 'emea-nl-be-sfcc-canvas',
    'nl-nl': 'emea-nl-be-sfcc-canvas',
  },
  customsBaseUrls: {
    DEV: 'https://dev3.vans.com/dev',
    QA: 'https://qa3.vans.com/qa2',
    PREPROD: 'https://preprod3.vans.com/preprod2',
    PROD: 'https://www.vans.com/prod'
  }
} as CustomsApiConfig
