import type { DialogConfig } from '#types/config/components/dialog'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    heading: 'title-2',
    seeResultsCta: 'flex-1',
    resetCta: 'flex-1'
  },
  dialogOptions: { type: 'panel' }
} satisfies RecursivePartial<DialogConfig['filters']>
