import type { RecursivePartial } from '#types/utils'
import type { DashboardConfig } from '#types/config/components/dashboard'

export default {
  brandClasses: {
    cta: '<md:w-full w-a',
    header: 'title-4 pt-3 pb-2'
  },
  favorites: {
    image: {
      height: 110,
      width: 88,
    },
    thumbnail: {
      height: 50,
      width: 40,
    },
    transformations: {
      alignFill: 'center',
      unSharpMask: 100
    }
  }
} satisfies RecursivePartial<DashboardConfig['card']>
