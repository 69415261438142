import type { VfConfig } from '#types/config/components/vf'

export default {
  iconSize: {
    sm: 'md',
    md: 'md',
    lg: 'md'
  },
  closeStyle: 'label'
} satisfies VfConfig['notification']
