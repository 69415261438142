import type { RecursivePartial } from '#types/utils'
import type { PaypalConfig } from '#types/config/components/paypal'

export default {
  style: {
    height: 50,
    shape: 'rect',
    borderRadius: 2,
    color: 'white',
    label: 'paypal'
  }
} satisfies RecursivePartial<PaypalConfig['button']>
