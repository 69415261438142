import type { RecursivePartial } from '#types/utils'
import type { KlarnaConfig } from '#types/config/components/klarna'

export default {
  brandClasses: {
    disclaimerText: '<md:text-sm',
    introText: 'mb-6 text-base',
    logo: 'ml-1'
  },
  showLogoHeader: true,
  showDialogTitle: true,
} satisfies RecursivePartial<KlarnaConfig>
