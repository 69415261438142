import type { ProductConfig } from '#types/config/components/product'

export default {
  brandClasses: {
    wrapper: 'mt-4 <lg:(mb-4 -mx-4) divide-y divide-grey-70 lg:(overflow-hidden b b-grey-70 rounded-sm)'
  },
  dialogOptions: {
    type: 'panel'
  }
} satisfies ProductConfig['shippingMethods']
