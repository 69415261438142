import type { BopisConfig } from '#types/config/components/bopis'
import type { RecursivePartial } from '#types/utils'

export default {
  storeDetails: {
    brandClasses: {
      addressLineSection: 'mt-4',
      columnWrapper: 'lg:cols-6',
      locationSection: 'col-span-3',
      storeAddress: 'text-sm',
      storeDirectionAndPhone: 'fw-medium',
      storeHours: 'fw-bold',
      storeHoursContent: 'c-black',
      storeProductTitle: 'fw-bold md:mb-2',
      productAttributes: 'c-black'
    },
    image: {
      width: 136,
      height: 170
    }
  }
} satisfies RecursivePartial<BopisConfig>
