import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    backToLink: 'flex items-center gap-1 <md:mb-6',
    billingSection: '<lg:pt-6 lg:pl-6',
    chevronIcon: 'md:hidden',
    containerHeading: 'mb-6 mt-8 items-center <lg:mb-8',
    estimatedDelivery: {
      container: 'mb-2',
      text: 'fw-bold text-sm'
    },
    informationSection: 'lg:(grid cols-2 divide-x) md:mb-10 divide-grey-70 <lg:divide-y',
    informationSectionDetails: 'grid cols-1 md:cols-2 gap-x-6 lg:gap-x-4',
    itemDescriptionDetails: 'text-sm lh-1',
    itemDescriptionDetailsTitle: 'pt-0 md:pt-4',
    orderInfoContainer: 'md:flex pb-12 pt-6',
    pricingText: '<md:flex',
    shipmentContainer: '<md:(flex between items-baseline)',
    shippingSection: 'pb-6 lg:pr-6',
    trackingNumber: 'flex gap-2 text-sm'
  },
  notificationVariant: 'left',
  presets: {
    size: {
      height: {
        sm: 125,
        md: 106
      },
      width: {
        sm: 100,
        md: 85
      }
    },
    transformations: {
      alignFill: 'center',
      unSharpMask: 100
    }
  },
  showEDD: true,
  trackReturnVariant: 'secondary'
} satisfies RecursivePartial<DetailsItemConfig>
