import { CreditCardTypes } from '#commerce/utils/creditCardTypes'
import type { CheckoutConfig } from '#types/config/checkout'
import type { CreditCardConfig } from '#types/creditCard'
import type { RecursivePartial } from '#types/utils'

export default {
  allowed: {},
  imageGroup: {
    US: (defaultValue: CreditCardConfig[]): CreditCardConfig[] => [
      ...defaultValue,
      CreditCardTypes.DINERS
    ]
  }
} satisfies RecursivePartial<CheckoutConfig['creditCards']>
