import type { ProductConfig } from '#types/config/components/product'
import type { RecursivePartial } from '#types/utils'

export default {
  badgePosition: 'bottom',
  brandClasses: {
    badge: 'm-1 bg-white/80',
    card: 'px-2',
    eyebrow: 'mt-2',
    gallery: '-mx-2 mb-2',
    title: 'mt-2 fw-bold',
    quickshopCta: 'absolute top-0 right-0 m-4'
  },
  dialogOptions: { type: 'panel' },
  mediaSize: {
    height: 550,
    width: 440
  },
  mediaTransformations: {
    alignFill: 'center',
    unSharpMask: 100
  }
} satisfies RecursivePartial<ProductConfig['card']>
