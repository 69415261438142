import type { VfConfig } from '#types/config/components/vf'

export default {
  sizes: {
    tagline: {
      width: 68,
      height: 28
    },
    loyalty: {
      width: 60,
      height: 41
    },
    vault: {
      width: 50,
      height: 22
    },
    full: {
      width: 25,
      height: 9
    },
    default: {
      width: 67,
      height: 23
    }
  }
} satisfies VfConfig['logo']
