import type { ApiConfig } from '#types/config/api'
import type { RecursivePartial } from '#types/utils'

export default {
  storeIdFields: {
    default: 'enterprise_store_identifier'
  },
  pickupPayloads: {
    default: {}
  }
} satisfies RecursivePartial<ApiConfig['brandify']>
