import type { ApplePayButton } from '#types/components/checkout/applePayButton'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    button: '!py-2.5 b-1 @hactive:(bg-grey-80 ring-0)'
  },
  icon: {
    width: 58
  },
  variant: 'secondary'
} satisfies RecursivePartial<ApplePayButton>
