import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    lastStep: 'truncate',
    item: 'shrink-0 truncate',
    wrapper: 'body-4 flex items-center gap-x-1'
  },
  homeLink: '/c/-5000',
  iconSize: 'md',
  linkVariant: 'quiet',
  separatorIcon: 'chevron',
  showFirstStepIcon: false
} satisfies VfConfig['breadcrumbs']
