import type { SignUpConfig } from '#types/config/components/form/signUp'
import type { RecursivePartial } from '#types/utils'

export default {
  isBirthDateRequired: false,
  isLoyaltyNewsletterAllowed: false,
  minimalAge: {
    CA: 18,
    US: 16
  },
  showBirthDateField: false,
  showMobilePhoneField: false
} satisfies RecursivePartial<SignUpConfig>
