import type { PdpConfig } from '#types/config/pages/pdp'
import type { RecursivePartial } from '#types/utils'

export default {
  allowPreselectShoeSize: true,
  brandClasses: {
    breadcrumbs: 'mr-2',
    details: 'lg:col-span-4 md:(col-span-5 row-start-2 row-end-5)',
    detailsSticky: 'md:pt-4',
    eSpotLazyAboveFooter: 'mt-4 <lg:-mx-4',
    eSpotsAndReviews: 'mt-4 md:mt-8 space-y-8',
    gallery: 'lg:col-span-8 md:(col-span-7 row-start-1 row-end-3 pt-4) <md:-mx-3',
    giftCardDetails: 'mx-4 lg:(mx-0 col-start-2 row-start-1 sticky top-0 shrink-0 px-4 pt-3)',
    giftCardGallery: 'lg:(col-start-1 row-start-1 row-span-2)',
    giftCardGrid: 'grid lg:(grid-cols-[4fr_1fr])',
    giftCardHeading: 'mx-4 mt-6 row-start-2 lg:(mx-0 mt-0 col-start-2 row-start-1 mb-6 sticky top-0 shrink-0 px-4 pt-6)',
    grid: 'mb-12 md:(grid mb-8 items-start gap-4 cols-12)',
    gridFullWidth: 'col-span-12',
    gridPartialWidth: 'lg:col-span-8 md:col-span-7',
    heading: 'pt-4 lg:col-span-4 md:col-span-5 <lg:mb-4',
    headingProductBadge: 'mb-2 bg-grey-80',
    headingProductName: 'title-2',
    headingSticky: 'mb-8 lg:mb-4',
    reviewsSearchForm: 'b b-grey-40 rounded-sm focus-within:b-grey-10',
    reviewsSearchInput: 'w-full b-0',
    reviewsSearchSubmit: 'px-1',
    reviewsSearchWrapper: '',
    variantSelectionWrapper: 'b-none pt-4 space-y-4',
    reviewsSnapshotWrapper: 'space-y-3',
    reviewsWrapper: 'text-sm',
    shopTheLookHeading: 'md:text-center',
    shopTheLookDialog: 'lg:w-125',
    shopTheLookWrapper: 'my-16'
  },
  breadcrumbsMaxItems: 2,
  details: {
    ctaSize: 'sm',
    ctaVariant: 'secondary',
    favoriteCta: {
      hideWhenNotifyMe: true,
      iconSize: 'lg',
      iconOnly: true,
      variantSelected: 'primary'
    },
    findInStoreCta: {
      showIcon: false
    }
  },
  disableGroupBy: true,
  eSpotsWithPageContext: ['lazy-above-footer'],
  gallery: {
    presets: {
      src: {
        alignFill: 'center',
        width: 458,
        height: 573,
        unSharpMask: 100
      },
      srcZoom: {
        alignFill: 'center',
        width: 2000,
        height: 2500,
        excludeDPR: true,
        sharpen: 0
      }
    }
  },
  giftCardLegalDisclaimerSize: 'sm',
  hideGiftCardBreadcrumbs: true,
  questions: {
    pageSize: 10
  },
  quickshop: {
    dialogOptions: {
      type: 'panel'
    }
  },
  reviews: {
    dialogOptions: {
      reviewWrite: { type: 'panel' },
      filters: { type: 'panel' }
    },
    pageSize: 10,
    iconSize: 'md',
    showFilters: true,
    showProsAndCons: false,
    showRatingBreakdown: false,
    showWriteReviewCta: false
  },
  seoMetaImage: '/img/logos/vans/default.svg',
  shopTheLook: {
    dialogOptions: { type: 'panel' },
    dialogSizes: { sm: 'lg', lg: 'sm' },
    mediaSize: {
      width: 120,
      height: 150
    },
    mediaTransformations: {
      alignFill: 'center',
      unSharpMask: 100
    },
    showLinkArrow: true
  },
  showBreadcrumbsLastStep: false,
  sizeAndFit: {
    showFitRating: false,
    showHeading: false,
    showModelMeasurements: false,
    showSizeChartIcon: false
  },
  sizeChart: {
    dialogOptions: { type: 'panel' }
  },
  swatches: {
    size: {
      sm: 'sm',
      lg: 'md'
    },
    variant: 'rectangle'
  },
  tryItOn: {
    apparelUrl: 'https://sneaker-window.vyking.io/vyking-assets/customer/{brand}/ar-asset-packages/{productId}/offsets.json',
    config: '/vyking/modeld-vans.bin',
    dialogOptions: { type: 'panel' },
    key: 'com.vans',
    src: 'https://sneaker-window.vyking.io/vyking-apparel/1/vyking-apparel.js'
  },
  upsells: {
    showCheckbox: true,
    showButton: false,
    showDetails: false
  }
} satisfies RecursivePartial<PdpConfig>
