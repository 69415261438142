import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    header: 'b-b b-grey-70',
    iconWrapper: 'lg:gap-3',
    logo: 'h-6',
    totalItems: 'top-0 bg-brand-3 fw-bold !text-xs',
    utilityNavigation: 'gap-4 pb-2 pt-4'
  },
  cartIconSize: 'md',
  excludeStickyRoute: (() => []) as unknown as string[],
  showOnScrollUp: true
} satisfies RecursivePartial<VfConfig['header']>
