import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    label: 'text-xs lh-2',
    ring: 'ring-white',
    swatchDisabled: '!h-6 !w-6 op-50',
    swatchInnerDisabled: '!inset-0.5 lh-2 text-lg'
  },
  hasIcon: false
} satisfies VfConfig['colorFilter']
