import type { PlpConfig } from '#types/config/pages/plp'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    breadcrumbs: '<lg:(absolute top-0 right-0 -mt-8 mx-4) lg:mt-9',
    container: 'px-4 lg:px-6 relative',
    eSpotAboveGrid: 'my-4 lg:my-6',
    eSpotLazyAboveFooter: ''
  },
  breadcrumbsMaxItems: 2,
  eSpotsWithPageContext: ['lazy-above-footer'],
  hideTitle: true,
  showBreadcrumbsLastStep: false,
  sorts: () => ({
    NORA: [
      'bestMatches',
      'whatsNew',
      'priceLowToHigh',
      'priceHighToLow',
      'rating',
      'topSellers',
    ],
    EMEA: [
      'bestMatches',
      'priceLowToHigh',
      'priceHighToLow',
      'rating',
      'topSellers',
      'whatsNew'
    ]
  })
} satisfies RecursivePartial<PlpConfig>
