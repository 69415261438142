import type { PagesConfig } from '#types/config/pages'

export default {
  defaultSrc: 'https://locations.vans.com/index.html',
  localizedSrc: [
    {
      lang: 'de-de',
      locales: ['de-at', 'de-be', 'de-ch', 'de-de', 'de-lu'],
      src: 'https://hosted.meetsoci.com/vanseu/index.html'
    },
    {
      lang: 'en-us',
      locales: ['en-cz', 'en-dk', 'en-fi', 'en-gb', 'en-ie', 'en-se'],
      src: 'https://hosted.meetsoci.com/vanseu/index.html'
    },
    {
      locales: ['es-es', 'pl-pl', 'pt-pt'],
      src: 'https://hosted.meetsoci.com/vanseu/index.html'
    },
    {
      lang: 'fr-ca',
      locales: ['fr-ca'],
      src: 'https://locations.vans.com/index.html'
    },
    {
      lang: 'fr-fr',
      locales: ['fr-be', 'fr-ch', 'fr-fr', 'fr-lu'],
      src: 'https://hosted.meetsoci.com/vanseu/index.html'
    },
    {
      lang: 'it-it',
      locales: ['it-ch', 'it-it'],
      src: 'https://hosted.meetsoci.com/vanseu/index.html'
    },
    {
      lang: 'nl-nl',
      locales: ['nl-be', 'nl-nl'],
      src: 'https://hosted.meetsoci.com/vanseu/index.html'
    },
  ]
} satisfies PagesConfig['storeLocator']
