import type { GiftCardConfig } from '#types/config/components/form/giftCard'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    amountThreshold: 'c-black'
  },
  showBadge: false,
  swatches: {
    size: {
      sm: 'sm',
      lg: 'md'
    },
    variant: 'rectangle'
  }
} satisfies RecursivePartial<GiftCardConfig>
