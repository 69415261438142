import type { RecursivePartial } from '#types/utils'
import type { ProductUpsellConfig } from '#types/config/components/product/upsell'

export default {
  brandClasses: {
    container: 'p-4 mb-4 bg-grey-90',
    title: 'title-3'
  },
  checkboxSize: 'md',
  image: {
    alignFill: 'center',
    height: 85,
    width: 68,
    unSharpMask: 100
  },
  buttonVariant: 'primary',
  dialogOptions: { type: 'panel' }
} satisfies RecursivePartial<ProductUpsellConfig>
