import type { CMSConfig } from '#types/config/components/cms'

export default {
  brandClasses: {
    carouselContainer: 'gap-0 lg:-mr-1',
    carouselControls: 'h-10 w-10 bg-brand-1/50 p-2 c-white shadow-sm @hactive:bg-brand-1/90'
  },
  presets: {
    size: {
      width: {
        sm: 390,
        md: 492
      },
      height: {
        sm: 488,
        md: 615
      }
    },
    transformations: {
      alignFill: 'center',
      unSharpMask: 100
    }
  }
} satisfies CMSConfig['productRecommendations']
