import type { ApiConfig } from '#types/config/api'

export default {
  // this config is needed in order to match the APIs cookie name
  brandPrefix: 'VANS',
  // this config is needed because OTW and VANS share cookies so when the user transition between them, he must have his info updated
  allowSharedCookies: true,
  // used to override CMS site ID, raw brand name is used by default
  cmsSiteId: 'canvas-vans'
} as ApiConfig
