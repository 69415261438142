import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  myLocationButtonVariant: 'secondary',
  cancelButtonVariant: 'tertiary',
  storesLayout: 'bopis',
  dialogOptions: {
    type: 'panel'
  },
  findStoresFormLayout: 'pickup'

} satisfies RecursivePartial<DialogConfig['pickupInStore']>
