import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    rectangle: {
      cross: 'rounded bg-white/60 ring ring-grey-50 bg-slash',
      swatch: 'rounded @hactive:(ring ring-black)',
      swatchActive: 'rounded ring ring-black',
      swatchInner: 'rounded'
    }
  },
  brandStyles: {
    rectangle: {
      container: {
        h: {
          sm: '4rem',
          md: '5.625rem',
          lg: '6.25rem'
        },
        w: {
          sm: '3.2rem',
          md: '4.5rem',
          lg: '5rem'
        }
      },
      cross: {
        inset: {
          sm: '0',
          md: '0',
          lg: '0'
        }
      },
      swatch: {
        inset: {
          sm: '0',
          md: '0',
          lg: '0'
        }
      },
      swatchUnavailable: {
        inset: {
          sm: '0',
          md: '0',
          lg: '0'
        }
      }
    }
  },
  config: {
    thumbnail: {
      rectangle: {
        alignFill: 'center',
        height: 70,
        width: 56,
        unSharpMask: 100
      },
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
