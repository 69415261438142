import type { BopisStoreSearchConfig } from '#types/config/components/form/bopisStoreSearch'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    footer: 'sticky bottom-0  bg-white p-4 -mx-4 lg:(-mx-6 px-6)',
    form: 'mt-4 space-y-4',
    heading: 'body-3 w-full',
    submitButton: 'w-full',
    list: 'grow pb-4 b-b b-grey-70 -mx-4 lg:-mx-6',
    wrapper: 'h-full f-col'
  },
  availabilityCheckboxSize: 'sm',
  noStoreExtendedDescription: true
} satisfies RecursivePartial<BopisStoreSearchConfig>
