import type { VfConfig } from '#types/config/components/vf'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    box: 'rounded-md px-2 text-xs',
    disabled: 'bg-slash-hollow bg-white b-grey-50 c-grey-30',
    label: 'py-0.5',
    selected: 'bg-grey-10 b-grey-10 c-white',
    unavailable: 'bg-slash-hollow bg-white b-grey-50 c-grey-30 @hactive:b-grey-10',
    unavailableSelected: 'bg-slash-hollow bg-white b-grey-10 c-grey-30',
    unselected: 'bg-white b-grey-40 c-grey-20 @hactive:(b-grey-10 c-grey-10)'
  },
  productAttributeSelectorSize: {
    fitType: 'sm',
    width: 'sm'
  }
} satisfies RecursivePartial<VfConfig['sizePicker']>
