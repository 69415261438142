import type { CheckoutConfig } from '#types/config/components/checkout'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    container: '<md:pb-8 md:(grid grid-flow-col py-6)',
    needHelp: 'fw-bold <md:pt-6 md:text-right'
  },
  helpUrl: '/help/contact-us',
  logoVariant: 'default',
  showNeedHelp: true,
} satisfies RecursivePartial<CheckoutConfig['footer']>
