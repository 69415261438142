import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    itemSummaryHeading: 'title-3 b-grey-50 mb-8 <md:(b-t pt-8) md:(b-b pb-2) lg:mb-2',
    returnedHeading: 'title-3 b-grey-70 pb-3 <md:b-b md:(mt-10 pb-0 title-5 -mb-2)'
  },
  groupReturnTrackingByShipment: true,
  showRewardsPaymentAfterOrderTotal: true
} satisfies RecursivePartial<DetailsItemConfig>
