import type { CMSConfig } from '#types/config/components/cms'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    nav: '',
    mobileBackground: 'bg-white',
    mobileWrapperOpened: 'bg-white'
  },
  closeIconSize: 'lg',
  linkIcon: 'arrow',
  mobileNavIconSize: 'md'
} satisfies RecursivePartial<CMSConfig['megaMenu']>
