import type { CheckoutConfig } from '#types/config/components/checkout'

export default {
  presets: {
    size: {
      width: 94,
      height: 118
    },
    transformations: {
      alignFill: 'center',
      unSharpMask: 100
    }
  }
} satisfies CheckoutConfig['orderItem']
