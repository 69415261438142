import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    back: 'duration rounded-sm p-1 @hactive:bg-brand-2',
    description: 'body-3',
    heading: 'subtitle-2',
    subheading: 'subtitle-4'
  },
  profileToastLogo: '/img/logos/vans/loyalty.svg',
  showInterestsStep: true
} satisfies RecursivePartial<DialogConfig['simplifiedEnrollment']>
