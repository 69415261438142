import type { RecursivePartial } from '#types/utils'
import type { TabsConfig } from '#types/config/components/vf/tabs'

export default {
  brandClasses: {
    primary: {
      trigger: ['pt-1 pb-3 fw-bold'],
      triggerActive: '!b-b-grey-10'
    }
  }
} satisfies RecursivePartial<TabsConfig>
