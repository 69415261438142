import type { CheckoutConfig } from '#types/config/components/checkout'

export default {
  brandClasses: {
    deliveryMethod: 'subtitle-4',
    newsletter: '',
    shippingMethodCode: 'items-center col-span-2',
    shippingMethodDeliveryTime: 'c-black my-1 capitalize col-span-3 <md:(order-2 ml-6)'
  },
  checkboxSize: 'sm',

} satisfies CheckoutConfig['addShippingAddress']
