import type { CheckboxConfig } from '#types/config/components/vf/checkbox'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    base: {
      selected: 'bg-black ring-black c-brand-2',
    },
    inverse: {
      selected: 'bg-brand-2 c-brand-1 brand-2',
    },
    md: {
      label: 'body-3',
      icon: 'mt-0.5',
      wrapper: 'gap-x-2'
    },
    sm: {
      label: 'body-4',
      icon: '',
    }
  },
  iconSize: {
    sm: 'sm',
    md: 'sm'
  },
  radius: {
    sm: '0.0625rem',
    md: '0.0625rem'
  },
} satisfies RecursivePartial<CheckboxConfig>
